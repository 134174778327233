


























import { Component, Vue } from "vue-property-decorator";
import MyForm from "@/components/MyForm.vue";
import Mixin from "@/js/mixins";

@Component({ components: { MyForm }, mixins: [Mixin] })
export default class ActivationAdd extends Vue {
  public user_id = "";
  public form_list: MyForm.formList[] = [];
  public shop: any = null; // 店铺信息

  formChange(form_list: MyForm.formList[]) {
    form_list.forEach((item, index) => {
      // 选择“合资”时才显示“合作对象”
      if (item.name === "jihuo") {
        if (item.value.length === 18) {
          this.getShopInfo(item.value);
        } else {
          this.shop = null;
        }
      }
    });
    this.form_list = form_list;
  }
  // 获取店铺信息
  getShopInfo(value) {
    let _this = this;
    this.$api.request({
      url: "common/shop/shop-sn-details",
      data: { shop_sn: value },
      success(res) {
        _this.shop = res.data;
      }
    });
  }
  // 提交表单
  submit() {
    let form: any = this.$refs.form;
    if (form.ValidateAll()) {
      this.submitRequest(this.$api.getFormData([this.form_list]));
    }
  }
  submitRequest(data: Dictionary<string>) {
    let _this = this;
    let shop_id = "";
    let user_id = this.user_id;
    if (this.shop) {
      shop_id = this.shop.shop_id;
    }
    this.$api.request({
      url: "common/shop/activation-shop",
      data: { user_id, shop_id },
      success(res) {
        console.log(res);
      }
    });
  }

  init() {
    this.user_id = String(this.$route.query.user_id || "");
    this.form_list = [
      {
        name: "jihuo",
        value: "",
        label: "激活编码",
        type: "input",
        placeholder: "请输入激活编码",
        required: true
      }
    ];
    this.$api.refreshForm([this.$refs.form]);
    this.shop = null;
  }
}
